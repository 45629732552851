@use "typography";
@use "colors";
@use "variables";

.row {
  display: grid;

  &.col-2 {
    grid-template-columns: 50% 50%;
  }
}

.back {
  a {
    align-items: start;
    display: flex;

    svg-icon {
      margin-right: 10px;
    }
  }
}

.logo {
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  min-width: 227px;
}

//Carousel dots
.slick-dots li {
  margin-top: 75px !important;
}

.slick-dots button {
  background: colors.$slider_dot !important;
  border-radius: 50% !important;
  height: 8px !important;
  width: 8px !important;
}

.slick-active button {
  background: colors.$color__green-10 !important;
  border-radius: 50% !important;
  height: 16px !important;
  margin-top: -4px;
  width: 16px !important;
}

@media (max-width: variables.$screen-desktop) {
  .only-desktop {
    display: none !important;
  }
  .only-mobile {
    display: block !important;
  }
}
@media (min-width: variables.$screen-desktop) {
  .only-desktop {
    display: block !important;
  }
  .only-mobile {
    display: none !important;
  }
}
