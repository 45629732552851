@use "antd.custom";
@use "buttons";
@use "colors";
@use "typography";
@use "table";
@use "misc";
@use "form";
@use "variables";

body, .ant-layout-content {
  background: colors.$color__grey-3;
  min-width: variables.$app-min-width;
}

@media (max-width: variables.$screen-desktop) {
  body, .ant-layout-content {
    background: colors.$color__white;
  }
}

@keyframes fadeInOut1 {
  0%, 35% {
    opacity: 1;
  }
  50%, 85% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOut2 {
  0%, 35% {
    opacity: 0;
  }
  50%, 85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.logo-slider {
  position: relative;
  width: 330px;
  height: 57px;

  svg-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 57px;
  }

  &.main_page svg-icon {
    top: 12px;
  }

  svg-icon:first-child {
    animation: fadeInOut1 7000ms ease-in-out infinite;
  }

  svg-icon:last-child {
    animation: fadeInOut2 7000ms ease-in-out infinite;
  }
}
