@use "colors";
@use "typography";
@use "variables";

.btn {
  align-items: center;
  border: 1px solid colors.$color__dark-8;
  border-radius: 8px;
  color: colors.$color__white;
  height: 55px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 600;
  gap: 4px;
  padding: 16px;

  &:hover {
    cursor: pointer;
  }

  &.btn-grey {
    background: colors.$color__grey-7;
    border: none;
    border-radius: 8px;
    color: colors.$color__white;
  }

  &.btn-green {
    background: colors.$color__green-10;
    border: none;
    border-radius: 8px;
    color: colors.$color__white;
  }

  &.nav-btn.green {
    font-size: 16px;
    background: colors.$color__green-10;
    color: colors.$color__white !important;
  }

  &.nav-btn.horizontal {
    @include typography.largeButton;
    border: 0;
    flex-direction: row;

    svg-icon {
      padding-top: 4px;
    }

    &:hover {
      color: colors.$color__green-10;

      svg-icon svg path {
        fill: colors.$color__green-10;
      }
    }
  }

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  &.green {
    border-color: colors.$color__green-10;
  }
}

.zoom-controls {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 997;

  button {
    background: colors.$color__white;
    border: 1px solid colors.$color__grey-5;
    border-radius: 4px;
    margin-left: 8px;
    padding: 11px;

    &:hover {
      cursor: pointer;
    }
  }
}

.ant-btn-primary {
  align-items: center;
  background: colors.$color__green-10;
  border: none;
  border-radius: 8px;
  color: colors.$color__white;
  display: flex;
  font-size: 1rem;//16px
  font-weight: 600;
  min-height: 32px;
  height: auto;
  justify-content: center;
  padding: 14px 24px;

  &:hover, &:focus, &:active {
    background: colors.$color__green-8;
  }
}

.btn-large {
  @include typography.largeButton;
}

.btn-large {
  @include typography.largeButton;
}

.mobile-nav-btn {
  display: block;
  margin-right: 24px;

  svg path {
    fill: colors.$color__white;
  }

  @media (min-width: variables.$screen-desktop) {
    display: none;
  }
}

.orders .btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 110px;

  &:hover {
    cursor: pointer;
  }
  @media (max-width: variables.$screen-desktop) {
    justify-content: center;
    margin: 20px 12px;
    text-align: center;
    width: calc(100% - 24px);
  }
}

.loading-icon-button {
  color: colors.$color__white;
  margin-left: 5px;
}
