$screen-xs:                  576px;
$screen-sm:                  576px;
$screen-md:                  768px;
$screen-lg:                  1200px;

$screen-xs-min:              $screen-xs;
$screen-sm-min:              $screen-sm;
$screen-md-min:              $screen-md;
$screen-lg-min:              $screen-lg;

$screen-phone:               $screen-xs-min;
$screen-tablet:              $screen-sm-min;
$screen-desktop:             $screen-lg-min;

$screen-xs-lower:            ($screen-xs-min - 1);
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

$mobile-navbar-height: 73px;
$desktop-navbar-height: 87px;

$app-min-width: 250px;
