@use "colors";
@use "typography";

.ant-modal-content {
  border-radius: 16px;
  padding: 48px;

  .ant-modal-header {
    border-bottom: none;
    padding: 0;

    .ant-modal-title {
      font-size: 1.5rem; //24px
      font-weight: 600;
    }
  }

  .ant-modal-body {
    padding: 8px 0;
    font-weight: 500;
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    padding: 24px 0 0 0;
  }

  .ant-modal-close-x {
    display: none;
  }
}

.cdk-overlay-container {
  z-index: 4000 !important;
}

.ant-tooltip-inner, .ant-tooltip-arrow span::before {
  @include typography.tableContent;
  background: colors.$color__dark-10;
  border-radius: 4px;
  color: colors.$color__white;
  padding: 8px;
}
