$color__dark-1: #E9EBEC;
$color__dark-2: #D3D6D9;
$color__dark-3: #BDC2C6;
$color__dark-4: #A7AEB3;
$color__dark-5: #919AA0;
$color__dark-6: #7B858D;
$color__dark-7: #65717A;
$color__dark-8: #4F5D67;
$color__dark-9: #394854;
$color__dark-10: #233441;

$color__grey-1: #F6F7F8;
$color__grey-2: #ECEEF0;
$color__grey-3: #E3E6E9;
$color__grey-4: #DADEE1;
$color__grey-5: #D0D6DA;
$color__grey-6: #C7CED2;
$color__grey-7: #BEC5CB;
$color__grey-8: #B4BDC4;
$color__grey-9: #ABB5BC;
$color__grey-10: #A1ADB5;
$color__grey-11: #CAD3DA;

$color__green-1: #F0F9EC;
$color__green-2: #E1F2DA;
$color__green-3: #D1ECC7;
$color__green-4: #C2E5B4;
$color__green-5: #B3DFA1;
$color__green-6: #A4D98F;
$color__green-7: #95D27C;
$color__green-8: #85CC69;
$color__green-9: #76C557;
$color__green-10: #67BF44;

$color__orange-1: #FDF2E9;
$color__orange-2: #FAE6D3;
$color__orange-3: #F8D9BD;
$color__orange-4: #F5CCA6;
$color__orange-5: #F3BF90;
$color__orange-6: #F0B37A;
$color__orange-7: #EEA664;
$color__orange-8: #EB9A4E;
$color__orange-9: #E98D38;
$color__orange-10: #E78022;

$color__white: #fff;

$color__error: #F65A5A;

$slider_dot: #C4C4C4;

$green-mint: #E1F0EF;
$baby-blue: #D6E7FB;

$color__mint_2: #C0EBD9;
$color__purple_1: #E6DBF1;

@mixin modalBoxShadow {
  box-shadow: 0 32px 58px rgba(0, 0, 0, 0.15);
}

@mixin bottomBoxShadow {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.15);
}

.white {
  color: $color__white !important;
}

.orange {
  color: $color__orange-10 !important;
}

.green {
  color: $color__green-10 !important;
}

.red {
  color: $color__error !important;
}

.dark {
  color: $color__dark-10 !important;

  &.link:hover {
    color: $color__dark-8 !important;
  }
}

.modal-text {
  color: $color__dark-10;
}

.dark-10 {
  color: $color__dark-10 !important;
}

.ant-form-item-label > label {
  color: $color__dark-10 !important;
}

.white-border {
  border: 1px solid $color__white !important;
}
