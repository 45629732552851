@use "colors";
@use "typography";

.table-filters {
  display: flex;
  list-style-type: none;
  padding: 0;

  li {
    @include typography.statusTag;
    background: colors.$color__white;
    border: 1px solid colors.$color__dark-10;
    border-radius: 8px;
    color: colors.$color__dark-10;
    margin-right: 20px;
    padding: 8px 12px;

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &.active {
      background: colors.$color__dark-10;
      color: colors.$color__white;
    }
  }
}

nz-table.table {
  overflow-x: auto;
  width: 100%;

  nz-spin {
    display: flex;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: colors.$color__grey-2;
    cursor: pointer;
  }

  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-item:hover,
  .ant-pagination-item-active {
    border-color: colors.$color__dark-10;
    color: colors.$color__dark-10;

    a {
      color: colors.$color__dark-10;
    }
  }

  th {
    &:before {
      width: 0 !important;
    }

    background: colors.$color__white;
    border-bottom: 1px solid colors.$color__dark-10;
    color: colors.$color__dark-10;
  }

  th {
    @include typography.tableStrong;
  }

  td {
    @include typography.tableContent;

    strong {
      @include typography.tableStrong;
    }
  }
}
