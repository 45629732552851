*, html, body {
  font-family: 'Montserrat';
}

@mixin h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

@mixin h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

h2 {
  @include h2;
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

h4 {
  @include h4;
}

.button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

.title-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

.large-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.medium-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
}

.medium-lighter-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

.label-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

.heading {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
}

@mixin semiBoldText {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

.semi-bold-text {
  @include semiBoldText;
}

.link {
  text-decoration-line: underline;

  &:hover {
    cursor: pointer;
  }
}

@mixin navButton {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

@mixin largeButton {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

@mixin largestButton {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

@mixin statusTag {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

@mixin tableContent {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
}

@mixin tableStrong {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
}

@mixin menuNavButtons {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

@mixin paragraph {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
}

@mixin logoText {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.02em;
}

@mixin strongText {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

@mixin titleText {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

@mixin normalSpan {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
}

@mixin boldText {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.paragraph {
  @include paragraph;
}

.h2 {
  @include h2;
}

.h4 {
  @include h4;
}
